import * as React from "react";
import styled from "styled-components";

import paperGeometric from "../../images/paperGeometric.svg";

const BackgroundWrapper = styled.div`
    position: relative;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    :last-child {
        border-bottom: none;
    }

    :after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.6;
        background-image: url(${paperGeometric});
        background-size: cover;
        background-position: top center;
    }
`;

export default BackgroundWrapper;
