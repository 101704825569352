import styled, { css } from "styled-components";

import { breakpoints, colors } from "../../helpers";

interface TextProps {
    disableMargin: boolean;
}

const commonStyles = css<TextProps>`
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: ${colors.typography};
    margin-top: 0;
    margin-bottom: 0;
`;

export const Headline1 = styled.h1<TextProps>`
    ${commonStyles};
    font-weight: 200;
    font-size: 26px;
    line-height: 36px;

    ${({ disableMargin }) =>
        !disableMargin &&
        css`
            margin-bottom: 30px;
        `};

    @media (min-width: ${breakpoints.small}px) {
        font-size: 32px;
        line-height: 42px;
    }

    @media (min-width: ${breakpoints.medium}px) {
        font-size: 38px;
        line-height: 48px;
    }
`;

export const Headline2 = styled.h2<TextProps>`
    ${commonStyles};
    font-weight: 200;
    font-size: 24px;
    line-height: 34px;

    ${({ disableMargin }) =>
        !disableMargin &&
        css`
            margin-bottom: 30px;
        `};

    @media (min-width: ${breakpoints.small}px) {
        font-size: 28px;
        line-height: 38px;
    }

    @media (min-width: ${breakpoints.medium}px) {
        font-size: 34px;
        line-height: 44px;
    }
`;

export const Copy = styled.p<TextProps>`
    ${commonStyles};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    ${({ disableMargin }) =>
        !disableMargin &&
        css`
            margin-bottom: 20px;
        `};

    strong {
        font-weight: 700;
    }
`;
