import * as React from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";

import { constants } from "../helpers";
import BackgroundWrapper from "./common/BackgroundWrapper";
import Separator from "./common/Separator";
import Header from "./header/Header";

interface Props {
    children: React.ReactNode;
    title?: string;
    openGraphDescription?: string;
    metaDescription?: string;
    metaKeywords?: string;
}

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        -webkit-text-size-adjust: none;
        -webkit-font-smoothing: antialiased;
    }
`;

const Master: React.FC<Props> = ({ children, title, metaDescription, openGraphDescription, metaKeywords }) => {
    const pageTitle = title ? `${title} - ${constants.fullCompanyName}` : constants.fullCompanyName;
    return (
        <>
            <Helmet defer={false}>
                <html lang={"de"} />
                <title>{pageTitle}</title>
                {!!metaDescription && <meta name="description" content={metaDescription} />}
                {!!metaKeywords && <meta name="keywords" content={metaKeywords} />}
                <meta name="google-site-verification" content="TbNEvf9XnYe1hG699KhCyBPvlyuyzZNhBZuHc2fKAGk" />
                <meta property="og:title" content={pageTitle} />
                {!!openGraphDescription && <meta property="og:description" content={openGraphDescription} />}
                <meta property="og:locale" content={"de"} />
                <meta property="og:site_name" content={constants.fullCompanyName} />
                <meta property="og:image" content="openGraph.jpg" />
                <link rel="shortcut icon" href="/favicon.ico" />
                <link rel="icon" type="image/png" href="/favicon.png" />
            </Helmet>
            <GlobalStyles />
            <BackgroundWrapper>
                <Header />
                <Separator />
                <main>{children}</main>
            </BackgroundWrapper>
        </>
    );
};

export default Master;
