import styled from "styled-components";

const Separator = styled.div`
    position: relative;
    height: 2px;
    background-color: white;
    left: calc(50% - 50vw);
    right: calc(50% - 50vw);
    width: 100vw;
`;

export default Separator;
