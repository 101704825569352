export enum breakpoints {
    small = 375,
    medium = 760,
    large = 1024,
}

export const colors = {
    typography: "#000000",
    link: {
        default: "#C4393E",
        hover: "#ff2828",
    },
};

export const constants = {
    officeEmail: "office@druck-ohne-troubles.at",
    rickyWebsite: "https://rickysmith.at",
    fullCompanyName: "DDM Druck & digitale Medien GmbH",
    shortCompanyName: "Druck & digitale Medien",
};

export const pageUrls = {
    home: "/",
    privacy: "/datenschutz",
};
