import * as React from "react";

import * as sc from "./Text.sc";

export enum variants {
    headline1,
    headline2,
    copy,
}

interface Props {
    children: React.ReactNode;
    variant?: variants;
    disableMargin?: boolean;
}

const Text: React.FC<Props> = ({ children, variant = variants.copy, disableMargin = false }) => {
    switch (variant) {
        case variants.copy:
            return <sc.Copy disableMargin={disableMargin}>{children}</sc.Copy>;

        case variants.headline1:
            return <sc.Headline1 disableMargin={disableMargin}>{children}</sc.Headline1>;

        case variants.headline2:
            return <sc.Headline2 disableMargin={disableMargin}>{children}</sc.Headline2>;
    }
};

export default Text;
