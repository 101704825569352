import GatsbyLink from "gatsby-link";
import styled from "styled-components";

export const LogoWrapper = styled.div`
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: right;
`;

export const Link = styled(GatsbyLink)`
    display: inline-block;
`;

export const Logo = styled.img`
    width: 200px;
`;
