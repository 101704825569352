import * as React from "react";
import ContentWrapper from "src/components/common/ContentWrapper";
import { pageUrls } from "src/helpers";
import logo from "src/images/logo.svg";

import * as sc from "./Header.sc";

const Header: React.FC = () => (
    <ContentWrapper>
        <sc.LogoWrapper>
            <sc.Link to={pageUrls.home}>
                <sc.Logo src={logo} alt={"Logo, Druck und digitale Medien"} title={"DDM Druck und digitale Medien GmbH"} />
            </sc.Link>
        </sc.LogoWrapper>
    </ContentWrapper>
);

export default Header;
